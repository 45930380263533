import { WithSubtitle, WithText, WithTitle } from '../../../withs';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import ModuleSection from '../../../organisms/ModuleSection';
import useTypeModuleImages from '../../../hooks/useTypeModuleImages';
import ContentLeftPushModule from '../../../modules/pushs/ContentLeftPushModule';
import GodfathersRanking from '../../../organisms/GodfathersRanking';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import ModuleContainer from '../../../organisms/ModuleContainer';
import { useMemo } from 'react';

export function StandardGodfatherBonusPresentationGameModule({
    game,
    title: forcedTitle,
    titleStyling,
    subtitle,
    subtitleStyling,
    text: forcedText,
    textCentered = false,
    textStyling,
    msVariant: variant,
    filterEmpty = true,
    displayQuantity = 3,
    period,
    variable = 'paidTickets',
    identityFormat = 'default',
    ...rest
}: StandardGodfatherBonusPresentationGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);
    const moduleImages = useTypeModuleImages('game', game);
    const image = useMemo(() => game?.godfatherBonusImage, [game?.godfatherBonusImage]);
    const title = forcedTitle || t('game_godfather_bonus_presentation_label');
    const text = forcedText || game?.godfatherBonusPresentation;

    return (
        <ModuleContainer {...zprops}>
            <ModuleSection
                title={title}
                titleStyling={titleStyling}
                subtitle={subtitle}
                subtitleStyling={subtitleStyling}
                text={undefined}
                textStyling={textStyling}
                variant={variant}
                defaultVariant={'bordered'}
                {...moduleImages}
                {...props}
            >
                {(!!text || !!image?.url) && (
                    <ContentLeftPushModule
                        image={image}
                        text={text}
                        textCentered={textCentered}
                        nopadding={(!!text && !image?.url) || (!text && !!image?.url)}
                    />
                )}
                <GodfathersRanking
                    game={game}
                    filterEmpty={filterEmpty}
                    displayQuantity={displayQuantity}
                    period={period}
                    variable={variable}
                    identityFormat={identityFormat}
                />
            </ModuleSection>
        </ModuleContainer>
    );
}

export interface StandardGodfatherBonusPresentationGameModuleProps extends WithTitle, WithSubtitle, WithText {
    // eslint-disable-next-line
    game: any;
    msVariant?: string;
    titleStyling?: string;
    subtitleStyling?: string;
    textStyling?: string;
    textCentered?: boolean;
    displayQuantity?: number;
    filterEmpty?: boolean;
    period?: string;
    variable?: string;
    identityFormat?: string;
}

export default StandardGodfatherBonusPresentationGameModule;
