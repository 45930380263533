import { WithTitle } from '@gotombola/react-types';
import useTypeTranslation from '../../../hooks/useTypeTranslation';
import useQueryString from '../../../hooks/useQueryString';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import ModuleContainer from '../../../organisms/ModuleContainer';
import GodfathersRanking from '../../../organisms/GodfathersRanking';

export function StandardGodfathersRankingGameModule({ game, ...rest }: StandardGodfathersRankingGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);
    const qs = useQueryString();

    if (!game.godfathersRankingsEnabled && !qs.has('f:godfathersrankings')) return null;

    return (
        <ModuleContainer {...zprops}>
            <GodfathersRanking title={t('godfathers_ranking_game_title')} game={game} {...props} />
        </ModuleContainer>
    );
}

export interface StandardGodfathersRankingGameModuleProps extends WithTitle {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    game: any;
    typeType: string;
    defaultFilter?: string;
    unit?: string;
    defaultSort?: string;
    period?: string;
    variable?: string;
}

export default StandardGodfathersRankingGameModule;
