import clsx from 'clsx';
import { WithImage, WithNopadding, WithOverline, WithText, WithTitle, WithChildren, WithVideo } from '../../withs';
import Button from '../../atoms/Button';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import SectionTitle from '../../atoms/SectionTitle';
import Text from '../../atoms/Text';
import { image } from '@gotombola/types';
import AnimatedImage from '../../atoms/AnimatedImage';
import VideoModule from '../VideoModule';
import { useMemo } from 'react';

export function ContentLeftPushModule({
    buttons,
    image,
    extraImages,
    nopadding = false,
    overline,
    overlineCentered,
    text,
    title,
    titleCentered,
    textCentered = true,
    children,
    videoUrl,
    videoPlaying = true,
}: ContentLeftPushModuleProps) {
    const { mediaShapeClass, blockShapeClass } = useStyling();
    const videoMode = !!videoUrl;
    const imageMode = !!image?.url;
    const hasMedia = useMemo(() => imageMode || videoMode, [imageMode, videoMode]);
    const hasContent = useMemo(() => !!text || !!title || !!overline || !!children, [text, title, overline, children]);

    return (
        <div className={clsx('flex flex-col sm:flex-row justify-between', !nopadding && 'p-8')}>
            {imageMode && (
                <div
                    className={clsx(
                        'w-full',
                        hasContent && 'sm:w-5/12 mb-4 sm:mb-0',
                        !hasContent && 'items-center flex flex-col',
                    )}
                >
                    <AnimatedImage
                        className={clsx('object-cover w-full h-full', mediaShapeClass)}
                        mainImage={image}
                        maxSize={800}
                        maxSizeTablet={700}
                        maxSizeMobile={500}
                        alt={image.alt || 'image item'}
                        extraImages={extraImages}
                    />
                </div>
            )}
            {videoMode && !imageMode && (
                <div
                    className={clsx(
                        'w-full',
                        hasContent && 'sm:w-5/12 mb-4 sm:mb-0',
                        !hasContent && 'items-center flex flex-col',
                    )}
                >
                    <VideoModule
                        url={videoUrl}
                        nopadding
                        hmargin={false}
                        playing={videoPlaying}
                        className={'object-cover w-full max-h-[300px] h-auto'}
                    />
                </div>
            )}
            {hasContent && (
                <div
                    className={clsx(
                        'w-full flex flex-col justify-center items-start',
                        hasMedia && 'sm:w-6/12',
                        !nopadding && 'p-6',
                    )}
                >
                    {!!overline && (
                        <div
                            className={clsx(
                                'text-sm font-medium text-gray-300 uppercase mb-1',
                                overlineCentered && 'text-center',
                            )}
                        >
                            <Text>{overline}</Text>
                        </div>
                    )}
                    <SectionTitle
                        title={title}
                        className={clsx('leading-none mb-4 sm:mb-8', titleCentered && 'mx-auto')}
                    />
                    {!!children && children}
                    {!!text && (
                        <div
                            className={clsx(
                                'w-full text-md font-light text-gray-600',
                                textCentered && 'text-center',
                                text['length'] > 100 && 'sm:text-justify',
                            )}
                        >
                            <Text>{text}</Text>
                        </div>
                    )}
                    {!!buttons?.length && (
                        <div className={clsx('space-x-4 mt-6 sm:mt-10', titleCentered && 'mx-auto')}>
                            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                            {buttons.map((button: any, index: number) => {
                                return (
                                    <Button
                                        className={clsx(
                                            'px-8 py-3 bg-white hover:bg-yellow-500 border-2 border-gray-300 hover:border-transparent text-black hover:text-white text-xl',
                                            blockShapeClass,
                                        )}
                                        key={index}
                                        {...button}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export interface ContentLeftPushModuleProps
    extends WithImage,
        WithNopadding,
        WithOverline,
        WithText,
        WithTitle,
        WithVideo,
        WithChildren {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    buttons?: any;
    titleCentered?: boolean;
    textCentered?: boolean;
    overlineCentered?: boolean;
    extraImages?: image[];
}

export default ContentLeftPushModule;
