import { useMemo } from 'react';
import { type_hierarchy_context } from '../types';
import useTypeTypeHierarchy from './useTypeTypeHierarchy';

export function useFirstTypeInHierarchyMatching(
    typeType: string,
    ctx: type_hierarchy_context,
    matcher: Function,
    mode: string,
    force = false,
) {
    const h = useTypeTypeHierarchy(typeType, ctx, mode);

    return useMemo(
        () =>
            (h.find((x: any) => (x && x[1] ? force || matcher(x[1], x[0]) : undefined)) || [undefined, undefined]) as [
                any,
                any,
            ],
        [h, matcher, force],
    );
}

export default useFirstTypeInHierarchyMatching;
