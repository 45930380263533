import { WithTitle } from '@gotombola/react-types';
import Ranking from '../../../organisms/type/Ranking';
import useQueryString from '../../../hooks/useQueryString';
import ModuleContainer from '../../../organisms/ModuleContainer';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import useTypeTranslation from '../../../hooks/useTypeTranslation';

export function StandardLeaderBoardGameModule({ game, ...rest }: StandardLeaderBoardGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const qs = useQueryString();
    const { t } = useTypeTranslation('game', game);

    if (!game.leaderboardEnabled && !qs.has('f:leaderboard')) return null;

    return (
        <ModuleContainer {...zprops}>
            <Ranking
                items={game?.amountRankedSellergroups?.items || []}
                game={game}
                title={t('leaderboard_game_title')}
                {...props}
            />
        </ModuleContainer>
    );
}

export interface StandardLeaderBoardGameModuleProps extends WithTitle {
    game: any;
    typeType: string;
    defaultFilter?: string;
    unit?: string;
    defaultSort?: string;
    property?: string;
    rawItems?: any[];
}

export default StandardLeaderBoardGameModule;
