import useTypeTranslation from '../../../hooks/useTypeTranslation';
import ModuleContainer from '../../../organisms/ModuleContainer';
import { WithZoneCustomization } from '../../../withs';
import useZoneCustomizationProps from '../../../hooks/useZoneCustomizationProps';
import ModuleSection from '../../../organisms/ModuleSection';
import CollectModeItem from '../../../molecules/CollectModeItem';
import { useMemo } from 'react';
import { image } from '@gotombola/types';
import clsx from 'clsx';
import BunchGiftCollectModeItem from '../../../molecules/BunchGiftCollectModeItem';

type prize_detail = {
    bunch: string;
    winner: string;
    ticket: string;
    ticketCode: string;
    ticketCustomCode?: string;
    bunchCode: string;
    bunchName: string;
    bunchMainImage?: image;
    rank: number;
    bunchRank: number;
};

export function StandardCollectModeGameModule({ game, presult, gifts, ...rest }: StandardCollectModeGameModuleProps) {
    const [zprops, props] = useZoneCustomizationProps(rest);
    const { t } = useTypeTranslation('game', game);
    const phone = game?.collectModePhone || game?.publicPhone || game?.organizationOwnerPhone || undefined;
    const email = game?.collectModeEmail || game?.publicEmail || game?.organizationOwnerEmail || undefined;
    const noPublicContact = game?.collectModeNoPublicContact || false;

    const presultBunches: prize_detail[] = (presult?.wonPrizesDetails?.prizes as prize_detail[] | undefined) || [];
    const gameBunches = useMemo(
        () =>
            game?.rankedBunches?.items.reduce((acc: any, bunch: any) => {
                acc = { ...acc, [bunch?.id || '']: bunch };
                return acc;
            }, {}) || {},
        [game?.rankedBunches?.items],
    );
    const filteredBunches: any[] =
        presultBunches
            ?.map((prize: prize_detail) => [prize, gameBunches[prize?.bunch || '']])
            ?.filter(
                ([prize, x]) =>
                    !!x &&
                    (x.collectModeMessage ||
                        x.collectModeEmail ||
                        x.collectModePhone ||
                        x.collectModes?.length ||
                        !!gifts?.[`${x.id}/${prize.bunchRank}`]),
            ) || [];

    const allHaveInstructions = useMemo(
        () => presultBunches?.length === filteredBunches?.length,
        [presultBunches?.length, filteredBunches?.length],
    );

    if (!presultBunches?.length || (!game?.collectModeMessage && !phone && !email && !filteredBunches?.length))
        return null;

    return (
        <ModuleContainer {...zprops}>
            <ModuleSection
                title={t('collect_mode_game_title', { count: presultBunches?.length })}
                defaultVariant={'bordered'}
                className={'flex flex-col gap-4'}
                {...props}
            >
                <div className={clsx('flex flex-col px-4 text-center sm:text-left gap-y-4 pb-4')}>
                    {!!filteredBunches?.length &&
                        filteredBunches?.map(([prize, bunch]: [any, any], i: number) => {
                            const c = (
                                <BunchGiftCollectModeItem
                                    key={i}
                                    game={game}
                                    bunch={bunch}
                                    prize={prize}
                                    gift={gifts?.[`${bunch.id}/${prize.bunchRank}`] || undefined}
                                />
                            );
                            if (!i) return c;
                            return (
                                <div className={'border-t pt-6'} key={i}>
                                    {c}
                                </div>
                            );
                        })}
                    {(!!game?.collectModeMessage || !!phone || !!email) && (
                        <div className={clsx(filteredBunches?.length && 'border-t pt-6')}>
                            <CollectModeItem
                                title={
                                    (!!filteredBunches?.length &&
                                        !allHaveInstructions &&
                                        t('presult_page_collect_mode_title_general')) ||
                                    undefined
                                }
                                text={
                                    (!!email &&
                                        !noPublicContact &&
                                        t('presult_page_collect_mode_contact_email', { email })) ||
                                    undefined
                                }
                                text2={
                                    (!!phone &&
                                        !noPublicContact &&
                                        t('presult_page_collect_mode_contact_phone', { phone })) ||
                                    undefined
                                }
                                message={game?.collectModeMessage}
                                buttonLabel={t('presult_page_collect_mode_email_contact_button_label')}
                                buttonTarget={
                                    (!!email &&
                                        `mailto:${email}?subject=${t('presult_page_collect_mode_email_contact_title', {
                                            count: presultBunches?.length,
                                            gameName: game?.name,
                                            gameCode: game?.code,
                                        })}`) ||
                                    undefined
                                }
                            />
                        </div>
                    )}
                </div>
            </ModuleSection>
        </ModuleContainer>
    );
}

export interface StandardCollectModeGameModuleProps extends WithZoneCustomization {
    game: any;
    presult: any;
    gifts?: any;
}

export default StandardCollectModeGameModule;
