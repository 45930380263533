import { WithButton, WithMessage, WithText, WithTitle } from '../withs';
import useTypeTranslation from '../hooks/useTypeTranslation';
import useGameContext from '@gotombola/react-contexts-type/lib/hooks/useGameContext';
import Button from '../atoms/Button';
import Text from '../atoms/Text';

export function CollectModeItem({
    title,
    link,
    linkText,
    linkLabel,
    text,
    text2,
    message,
    buttonLabel,
    buttonTarget,
    linkTargetMode,
}: CollectModeItemProps) {
    const { game } = useGameContext();
    const { t } = useTypeTranslation('game', game);

    return (
        <div className={'space-y-2'}>
            {!!title && <Text className={'text-xl'} bold>{`:gift:&nbsp;&nbsp;${title}`}</Text>}
            {!!link && (
                <div className={''}>
                    {!!linkText && <Text className={'py-4'}>{linkText}</Text>}
                    <Button
                        className={'text-sm p-1'}
                        label={linkLabel || link}
                        target={link}
                        targetMode={linkTargetMode}
                    />
                    <Text className={'py-4'}>{t('presult_page_collect_mode_link_helper_text', { link })}</Text>
                </div>
            )}
            {(!!text || !!text2) && (
                <>
                    <Text light>{t('presult_page_collect_mode_contact')}</Text>
                    {!!text && <Text light>{text}</Text>}
                    {!!text2 && <Text light>{text2}</Text>}
                </>
            )}
            {!!message && <Text className={'py-4'}>{message}</Text>}
            {!!buttonLabel && buttonTarget && (
                <div>
                    <div className={'mt-6'}>
                        <Button className={'text-sm p-1'} label={buttonLabel} target={buttonTarget} />
                    </div>
                </div>
            )}
        </div>
    );
}

export interface CollectModeItemProps extends WithMessage, WithButton, WithText, WithTitle {
    text2?: string;
    link?: string;
    linkText?: string;
    linkLabel?: string;
    linkTargetMode?: string;
}

// noinspection JSUnusedGlobalSymbols
export default CollectModeItem;
