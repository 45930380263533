import loadable from '@loadable/component';
import { GameModuleProps } from '../../types';
import ModuleMode from '../../organisms/ModuleMode';

const modes = {
    standard: loadable(() => import('./godfatherBonusPresentation/StandardGodfatherBonusPresentationGameModule')),
};

export function GodfatherBonusPresentationGameModule(props: GodfatherBonusPresentationGameModuleProps) {
    return <ModuleMode {...props} modes={modes} defaultMode={'standard'} />;
}

export type GodfatherBonusPresentationGameModuleProps = GameModuleProps;

export default GodfatherBonusPresentationGameModule;
