import { WithTitle } from '@gotombola/react-types';
import Ranking from './type/Ranking';
import { useCallback, useMemo } from 'react';
import { formatIdentity } from '@gotombola/censure';

export function GodfathersRanking({
    game,
    period,
    variable = 'paidTickets',
    identityFormat = 'default',
    typeType = 'game',
    ...props
}: GodfathersRankingProps) {
    const property = useMemo(() => {
        const suffix = variable.slice(0, 1).toUpperCase() + variable.slice(1);
        if ('current' === period) return `statCurrentPeriod${suffix}`;
        if ('previous' === period) return `statPreviousPeriod${suffix}`;
        return;
    }, [period, variable]);
    const computeTitle = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (x: any) => formatIdentity(x, identityFormat),
        [identityFormat],
    );

    if (!game.godfathersRankingsEnabled) return null;

    return (
        <Ranking
            items={game?.amountRankedGodfathers?.items}
            property={property}
            computeTitle={computeTitle}
            game={game}
            typeType={typeType}
            {...props}
        />
    );
}

export interface GodfathersRankingProps extends WithTitle {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    game: any;
    typeType?: string;
    defaultFilter?: string;
    unit?: string;
    defaultSort?: string;
    period?: string;
    variable?: string;
    displayQuantity?: number;
    filterEmpty?: boolean;
    identityFormat?: string;
}

export default GodfathersRanking;
