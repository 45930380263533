import CollectModeItem from './CollectModeItem';
import useTypeTranslation from '../hooks/useTypeTranslation';
import { useMemo } from 'react';

export function BunchGiftCollectModeItem({ game, bunch, gift }: BunchGiftCollectModeItemProps) {
    const { t } = useTypeTranslation('game', game);

    const link = useMemo(
        () => gift?.collectModeUrl || bunch?.collectModeUrl,
        [gift?.collectModeUrl, bunch?.collectModeUrl],
    );
    const linkType = useMemo(
        () => gift?.collectModeUrlType || bunch?.collectModeUrlType || 'generic',
        [gift?.collectModeUrlType, bunch?.collectModeUrlType, t],
    );
    const linkText = useMemo(
        () =>
            gift?.collectModeUrlText ||
            bunch?.collectModeUrlText ||
            t([`presult_page_collect_mode_url_text_${linkType}`, 'presult_page_collect_mode_url_text']),
        [gift?.collectModeUrlText, bunch?.collectModeUrlText, linkType, t],
    );
    const linkLabel = useMemo(
        () =>
            gift?.collectModeUrlLabel ||
            bunch?.collectModeUrlLabel ||
            t([`presult_page_collect_mode_url_button_label_${linkType}`, 'presult_page_collect_mode_url_button_label']),
        [gift?.collectModeUrlLabel, bunch?.collectModeUrlLabel, linkType, t],
    );
    const linkTargetMode = useMemo(() => {
        switch (linkType) {
            case 'voucher':
                return '_blank';
            default:
            case 'generic':
                return undefined;
        }
    }, [linkType]);
    const email = useMemo(
        () => gift?.collectModeEmail || bunch?.collectModeEmail,
        [gift?.collectModeEmail, bunch?.collectModeEmail],
    );
    const phone = useMemo(
        () => gift?.collectModePhone || bunch?.collectModePhone,
        [gift?.collectModePhone, bunch?.collectModePhone],
    );
    const title = useMemo(() => gift?.name || bunch?.name, [gift?.name, bunch?.name]);
    const message = useMemo(
        () => gift?.collectModeMessage || bunch?.collectModeMessage,
        [gift?.collectModeMessage, bunch?.collectModeMessage],
    );
    const buttonTarget = useMemo(
        () =>
            email
                ? `mailto:${email}?subject=${t('presult_page_collect_mode_email_contact_title', {
                      gameName: game?.name,
                      gameCode: game?.code,
                  })}`
                : undefined,
        [email, t, game?.name, game?.code],
    );
    const buttonLabel = useMemo(
        () => (buttonTarget ? t('presult_page_collect_mode_email_contact_button_label') : undefined),
        [buttonTarget, t],
    );

    const text = useMemo(
        () => (!!email && t('presult_page_collect_mode_contact_email', { email })) || undefined,
        [email, t],
    );
    const text2 = useMemo(
        () => (!!phone && t('presult_page_collect_mode_contact_phone', { phone })) || undefined,
        [phone, t],
    );

    return (
        <CollectModeItem
            title={title}
            linkText={linkText}
            linkLabel={linkLabel}
            link={link}
            text={text}
            text2={text2}
            message={message}
            buttonLabel={buttonLabel}
            buttonTarget={buttonTarget}
            linkTargetMode={linkTargetMode}
        />
    );
}

export interface BunchGiftCollectModeItemProps {
    game: any;
    bunch?: any;
    prize?: any;
    gift?: any;
}

// noinspection JSUnusedGlobalSymbols
export default BunchGiftCollectModeItem;
